import { createContext, useContext, useEffect, useState } from "react";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const GetUserDetails = async()=>{
      const userData = await sessionStorage.getItem("user");
    if (userData) {
      setUser(JSON.parse(userData));
    } else {
      console.log("User data not found in session storage");
    }
    };
    GetUserDetails();
  }, []);

  return (
    <UserContext.Provider value={{ user }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};
