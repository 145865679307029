import { Tab, Tabs } from "@nextui-org/react";
import SingleDateReport from "./SingleDateReport";
import BetweenDateReport from "./BetweenDateReport";
import DataNameReport from "./DataNameReport";
import TotalDataReport from "./TotalDataReport";

const CallingReport = ()=>{
return(
    <>
         
         <Tabs aria-label="Options" className="shadow-sm shadow-black rounded-xl md:w-fit w-full">
        <Tab key="daily report" title="Daily Report">
        
              <SingleDateReport />
           
        </Tab>
        <Tab key="Monthly Report" title="Monethly Report " >
          
              <BetweenDateReport />
        </Tab>
        <Tab key="Data_name" title="Data Name Report" >
          
              <DataNameReport />
        </Tab>

        {/* <Tab key="Total Data" title="Total Data" >
          
              <TotalDataReport />
        </Tab> */}
        
      </Tabs>
        
    </>
);
}
export default CallingReport;