

import React, { useState, useEffect } from "react";
import axios from "axios";

import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Input,
  Button,
  Textarea,
} from "@nextui-org/react";
import { Search } from "@mui/icons-material";

import "./index.css"; 
import axiosInstance from './../../../Auth/axios';

const columns = [{ name: "Select All", uid: "name" }];

const AddTask = () => {
  
    const [addTaskTitle, setAddTaskTitle] = useState("");
  const [taskAssign, setTaskAssign] = useState("");
  const [description, setDescription] = useState("");
  const [taskDurationTime, setTaskDurationTime] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [selectedKeys, setSelectedKeys] = useState(new Set());
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(`https://sdmipl.com/crm-portal/api/employee/detailes`);
        setUsers(response.data.employeedata);
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, []);

  const handleTaskTitleChange = (event) => {
    setAddTaskTitle(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const hasSearchFilter = Boolean(filterValue);
  const headerColumns = columns;

  const filteredItems = users.filter((user) =>
    user.name.toLowerCase().includes(filterValue.toLowerCase())
  );

  const items = filteredItems;

  const renderCell = (user, columnKey) => {
    const cellValue = user[columnKey];

    switch (columnKey) {
      case "name":
        return (
          <div className="flex items-center gap-3">
            <img src={user.profile_photo_url} alt={user.name} className="rounded-full w-7 h-7"/>
            <p className=" font-sans font-medium">{user.name}</p>
          </div>
        );
      default:
        return cellValue;
    }
  };

  const onSearchChange = (event) => {
    setFilterValue(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let selectedUserIds;

    if (selectedKeys === "all") {
      selectedUserIds = users ? users.map((user) => user.id) : [];
    } else if (selectedKeys.size > 0) {
      selectedUserIds = Array.from(selectedKeys);
    } else {
      alert("Please select some users to submit.");
      return;
    }

    setSelectedKeys(new Set());

    try {
      const response = await axiosInstance.post(`https://sdmipl.com/crm-portal/api/add/task`, {
        task: addTaskTitle,
        assignBy: taskAssign,
        description: description,
        emp_id: selectedUserIds,
        duration_date: selectedDate,
        duration_time: taskDurationTime,
      });
      console.log(response.data);
      setAddTaskTitle("");
      setDescription("");

      alert("Task Succesfully Assigned");

    } catch (err) {
      console.error(err);
    }
  };



  return (
    <form
      className=" "
      onSubmit={handleSubmit}
    >

      <div className="grid grid-cols-1  gap-4 md:grid-cols-3">
        <div className={`col-span-1 rounded-lg h-auto p-2 shadow-sm shadow-black border-2 dark:border-slate-700 border-neutral-300 border-double`}>
          
          <h2 className="font-bold text-lg">Select Employees</h2>

          <div
            className="flex flex-col gap-3 relative p-3 "
            style={{ height: "calc(100vh - 280px)" }}
          >
            <div>
              <Input
                isClearable
                placeholder="Search by name..."
                startContent={<Search />}
                size="sm"
                value={filterValue}
                variant="faded"
                onValueChange={onSearchChange}
                classNames="shadow-sm shadow-black rounded-xl"
              />
            </div>

            <Table
              aria-label="Select Employee"
              isHeaderSticky
              selectionMode="multiple"
              bottomContentPlacement="outside"
              selectedKeys={selectedKeys}
              topContentPlacement="outside"
              onSelectionChange={setSelectedKeys}
              classNames={{
                base: "max-h-[520px] overflow-auto h-[cal(100%-30px)]",
                table: "min-h-[420px]",
              }}
            >
              <TableHeader columns={headerColumns}>
                {(column) => (
                  <TableColumn key={column.uid}>{column.name}</TableColumn>
                )}
              </TableHeader>
              <TableBody emptyContent={"No users found"} items={items}>
                {(item) => (
                  <TableRow key={item.id}>
                    {(columnKey) => (
                      <TableCell>{renderCell(item, columnKey)}</TableCell>
                    )}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </div>

        <div
          className="flex flex-col gap-5 col-span-2 rounded-lg  p-5 shadow-sm shadow-black border-2 dark:border-slate-700 border-neutral-300 border-double "
        >
          <Input
            isRequired
            isClearable
            type="text"
            name="task"
            label="Task Title"
            variant="faded"
           className="shadow-sm shadow-black rounded-xl"
            onChange={handleTaskTitleChange}
          />

          <Input
            isRequired
            isClearable
            type="text"
            name="task"
            label="Assign By"
            variant="faded"
           className="shadow-sm shadow-black rounded-xl"
            onChange={(e) => setTaskAssign(e.target.value)}
          />

          <div className="grid grid-cols-1  gap-4 md:grid-cols-2 ">
            <Input
              isClearable
              isRequired
              type="date"
              name="task_day"
              onChange={(e) => setSelectedDate(e.target.value)}
              label="Date to Complete Task"
              placeholder="Date to Complete Task"
             variant="faded"
           className="shadow-sm shadow-black rounded-xl"
            />

            <Input
              isClearable
              isRequired
              type="time"
              name="task_time"
              label="Time to Complete Task"
              placeholder="Date to Complete Task"
              variant="faded"
           className="shadow-sm shadow-black rounded-xl"
              onChange={(e) => setTaskDurationTime(e.target.value)}
            />
          </div>


          <Textarea value={description}
            onChange={handleDescriptionChange}
            variant="faded"
            placeholder="Enter Task Description Here...." className="  bg-default-200 after::bg-blue-500  shadow-sm shadow-black rounded-xl"/>
        </div>
      </div>
      <div className="flex justify-end mt-4">
        <Button  color="primary" variant="shadow" className="bg-gradient-to-l to-cyan-400 from-blue-400 text-white font-sans font-medium" type="submit">
          Assign Task
        </Button>
      </div>
    </form>
  );
};

export default AddTask;

