
import {  useEffect, useState } from "react";
import Papa from "papaparse";
import './uploadData.css';

import { Button, Input, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow} from "@nextui-org/react";

import useSWR,{mutate} from "swr";
import axiosInstance from './../../Auth/axios';





const acceptableCSVFileTypes =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .csv";


  const columns = [{ name: "Select All", uid: "name" }];
const UploadAssignStdData = ()=>{
     
  const [fileData, setFileData] = useState(null);
  const [DataName, setDataName] = useState("");
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  
  const [selectedKeys, setSelectedKeys] = useState(new Set());
   
  const headerColumns = columns;
  
  
  const [users, setUsers] = useState([]);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(`https://sdmipl.com/crm-portal/api/employee/detailes`);
        setUsers(response.data.employeedata);
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, []);
  
  
    const handleFile = (e) => {
      const file = e.target.files[0];
      Papa.parse(file, {
        skipEmptyLines: true,
        header: true,
        complete: function (results) {
          
          setFileData(results.data);
          
        },
      });
    };
  
    const renderCell = (user, columnKey) => {
      const cellValue = user[columnKey];
  
      switch (columnKey) {
        case "name":
          return (
            <div>
              
              <p className="font-bold dark:text-[#cacaca] text-[#27272A] text-lg">{user?.name}</p>
           
              <p className="dark:text-[#cacaca] text-[#27272A]">{user?.email}</p>
            </div>
          );
        default:
          return cellValue;
      }
    };
  
    const handleFileSubmit = async (e) => {
      
     
      e.preventDefault();
      try {
        if (fileData && DataName) {
          const updatedFileData = fileData.map((row) => {
            return {
              ...row,
              data_name: DataName,
            };
           
          });
  
         
  
          const response = await axiosInstance({
            method: "post",
            url: `https://sdmipl.com/crm-portal/api/student/registration`,
            data: {
              fileData: updatedFileData,
            },
          });
  console.log(response.data)
          // messageApi.success("Student CSV file data successfully inserted!");
           
        } else {
          alert("File data or selected course is missing.");
        }
      } catch (err) {
        console.log(err);
      }
    };
  
    
  
  
  
   
  
  
   
  
  
  const handleAssignEmp = async (e) => {
    e.preventDefault();
    let selectedUserIds;
  
      if (selectedKeys === "all") {
        selectedUserIds = users ? users.map((user) => user.id) : [];
      } else if (selectedKeys.size > 0) {
        selectedUserIds = Array.from(selectedKeys);
      } else {
        
        alert("Please select employees");
        return;
      }
  
      setSelectedKeys(new Set());
      console.warn("Assign Employee",  selectedUserIds);
  
    try {
      const response = await axiosInstance({
        method: "post",
        url: `https://sdmipl.com/crm-portal/api/assignemployee/data`,
  
        data: {
          assignEmp: selectedUserIds,
        },
      });
      console.log(response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  
  
  const AllEmployeeData =users?.map(item => item);

return(
    <>
        <div className="w-full flex flex-col md:flex-row  gap-5 justify-evenly items-start  ">
        <form
          className="flex w-full md:w-5/12 flex-wrap     shadow-black rounded-lg shadow-sm text-black px-2 py-5 md:py-5 md:px-5 gap-5"
          onSubmit={handleFileSubmit}
          encType="multipart/form-data"
          method="post"
        >
          <input
            type="file"
            name="csvFile"
            accept={acceptableCSVFileTypes}
            className={`p-4 w-full border-gray-400 bg-white  dark:bg-[#18181B]  text-black dark:text-white border-dashed cursor-pointer shadow-sm outline-none  shadow-black rounded-lg formInput`}
            placeholder="Choose your file"
            onChange={handleFile}
          />

          <input
            type="text"
            name="data_name"
            className="w-full bg-inherit text-black dark:text-white bg-white dark:bg-[#18181B] shadow-sm rounded-md outline-none  shadow-black p-2"
            onChange={(e) => setDataName(e.target.value)}
            placeholder="Enter Data Name ex. MP MBBS Data"
          />

          <div className="flex flex-wrap justify-center gap-3">
            <Button
              type="submit"
              color="primary"
              className="bg-gradient-to-br to-blue-500 from-sky-400"
            >
              Upload
            </Button>
          </div>
        </form>

      

        <form onSubmit={handleAssignEmp} className="w-full   p-2  rounded-lg  shadow-sm shadow-[#000000] ">
       <h1 className="text-center text-xl my-2 text-black dark:text-slate-300">Assign Data</h1>
        <Table
       
              aria-label="Select Employee"
            
              selectionMode="multiple"
              bottomContentPlacement="outside"
              selectedKeys={selectedKeys}
              topContentPlacement="outside"
              onSelectionChange={setSelectedKeys}
              classNames={{
                base:  " w-full max-h-[400px]  rounded-lg overflow-auto h-[cal(100%-30px)] backdrop-blur-sm",
                table: "w-full min-h-[320px] "
              }}
            >
              <TableHeader columns={headerColumns}>
                {(column) => (
                  <TableColumn key={column.uid}>{column.name}</TableColumn>
                )}
              </TableHeader>
              <TableBody emptyContent={"No users found"} items={users}>
                {(item) => (
                  <TableRow key={item.id}>
                    {(columnKey) => (
                      <TableCell>{renderCell(item, columnKey)}</TableCell>
                    )}
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Button type="submit" color="warning" className="bg-gradient-to-tl from-amber-600 to-yellow-500 text-white my-2"  >Assign Employee</Button>
        </form>
      </div>
    </>
);
}
export default UploadAssignStdData;