import React, { useState } from 'react';
import {  Table,  TableHeader,  TableBody,  TableColumn,  TableRow,  TableCell,  SelectItem, Select} from "@nextui-org/react";
import useSWR from 'swr';
import axiosInstance from '../../Auth/axios';

const ReferencedData = () => {
    const columns = [
      
        { name: "NAME", uid: "contact_name" },
        { name: "Email", uid: "email_address" },
        { name: "Number", uid: "contact_number" },
        
        { name: "Course Name", uid: "course_name" },
        { name: "Neet Score", uid: "course_name" },
      
        { name: "Call Date", uid: "calling_date" },
        { name: "Address", uid: "contact_address" },
        { name: "Feedback", uid: "student_feedback" },
      ];

      const [referenceData,setReferenceData] = useState([]);
      const req = async (url) => {
        try {
          const response = await axiosInstance({
            method: "get",
            url: url,
          });
          
          return response.data;
        } catch (err) {
          return err.response;
        }
      };
    
      const { data, error } = useSWR(
        `https://sdmipl.com/crm-portal/api/employee/detailes`,
        req,
        {
          revalidateInterval: 3000,
        }
      );

      const handleSelectEmployee = async (data)=>{
        console.log(data.target);
        try {
            const response = await axiosInstance({
              method: "post",
              url: `https://sdmipl.com/crm-portal/api/employee/assigndata/refrence`,
              data:{
                emp_id:data.target.value
              }
            });
          
            setReferenceData(response.data.studentsdata);
            
          } catch (err) {
            console.log("Error in ReferencedData:", err.response);
            
           
          }
      }
    
      

  return (
    <div className=''>
    <div className='flex gap-4'>
    <Select
      items={data && data?.employeedata?.map((item) => ({ value: item.id, label: item.name }))}
      label="Select Employee"
      aria-label='select Employee'
      variant='faded'
      className="w-full shadow-sm shadow-black rounded-xl mb-4"
      onChange={(value) => handleSelectEmployee(value)}
    >
      
      {data &&
              data?.employeedata?.map((items, index) => {
                return (
                  <SelectItem key={items.id} value={items.id}>{items.name}</SelectItem>
                );
              })}
    </Select> 
   
    </div>
       <Table aria-label='referenced data'>
     <TableHeader>
        {columns.map((column,index) =>
          <TableColumn key={index}>{column.name}</TableColumn>
        )}
      </TableHeader>
      <TableBody emptyContent={"No Data to display"}>
      {referenceData && referenceData.map((row, index) => (
    <TableRow key={index}>
      <TableCell >{row.contact_name}</TableCell>
      <TableCell >{row.contact_email}</TableCell>
      <TableCell >{row.contact_number}</TableCell>
      
      <TableCell >{row.course_name}</TableCell>
      <TableCell >{row.neet_score}</TableCell>
     
      <TableCell >{row.calling_date}</TableCell>
      <TableCell >{row.contact_address}</TableCell>
      <TableCell >{row.student_feedback}</TableCell>
    </TableRow>
  ))}
      </TableBody>
    </Table> 

    </div>
  )
}

export default ReferencedData;