import useSWR, { mutate } from "swr";

import Papa from "papaparse"
import { Button, Modal, ModalBody, ModalContent, ModalHeader, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, Tooltip, useDisclosure } from "@nextui-org/react";
import axiosInstance from "../../Auth/axios";
import "./employeeData.css"
import { CloudUpload, Delete, Edit, Person, PersonAdd } from "@mui/icons-material";
import { useState } from "react";
import { IconButton } from "@mui/material";
const acceptableCSVFileTypes ="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .csv";
const EmployeesData = ()=>{
    const SignupData = {
        name: "",
        email: "",
        password: "",
        mobile: "",
        role: "",
        gender: "",
      };
    
      const [editedItem, setEditedItem] = useState({
        id: "",
        name: "",
        email: "",
        password: "",
        mobile: "",
        role: "",
      });
    
      const [open, setOpen] = useState(false);
      const [openForm, setOpenForm] = useState(false);
      const [openUpload, setOpenUpload] = useState(false);
      const [newUser, setNewUser] = useState(SignupData);
    
     const [fileData, setFileData] = useState([]);
    
     
    //Modals 
    const {isOpen:CreateIsOpen, onOpen:CreateOnOpen, onOpenChange:CreateOnOpenChange} = useDisclosure();
    const {isOpen:UploadIsOpen, onOpen:UploadOnOpen, onOpenChange:UploadOnOpenChange} = useDisclosure();
    
      const TableName = [
        { label: "ID" },
        { label: "Name" },
        { label: "Mobile" },
        { label: "Email" },
        { label: "Role" },
        { label: "Gender" },
        { label: "Status" },
        
        // { label: "Delete Employee" },
      ];
    
      const handleClose = () => {
        setOpen(false);
      };
    
      const handleCloseForm = () => {
        setOpenForm(false);
      };
    
      const handleCloseUpload = () => {
        setOpenUpload(false);
      };
    
      const handleUpdateValue = (e) => {
        const key = e.target.name;
        const value = e.target.value;
        setNewUser((oldData) => ({
          ...oldData,
          [key]: value,
        }));
      };
    
      const handleEdit = ({ items }) => {
        // console.log(items)
        setEditedItem({
          id: items.id,
          fullname: items.name,
          email: items.email,
          mobile: items.mobile,
          role: items.role,
          password: items.password,
        });
        setOpen(true);
      };
    
    
      const req = async (url) => {
        try {
          const response = await axiosInstance({
            method: "get",
            url: url,
          });
          
          return response.data;
        } catch (err) {
          return err.response;
        }
      };
    
      const { data, error } = useSWR(
        `https://sdmipl.com/crm-portal/api/employee/detailes`,
        req,
        {
          revalidateInterval: 3000,
        }
      );
    
      
    
       const handleFile = (e) => {
         const file = e.target.files[0];
         Papa.parse(file, {
           skipEmptyLines: true,
           header: true,
           complete: function (results) {
            
             setFileData(results.data);
           },
         });
       };
       
        const handleFileSubmit = async (e) => {
          e.preventDefault();
          try {
              const response = await axiosInstance({
              method: "post",
              url: `https://sdmipl.com/crm-portal/api/employee/register/csv`,
              data:fileData
            });
            
            mutate(`https://sdmipl.com/crm-portal/api/employee/detailes`);
          } catch (err) {
            alert(err);
          }
        };
    
      const CreateEmployee = async (e) => {
        e.preventDefault();
        try {
        const response =  await axiosInstance({
            method: "post",
            url: `https://sdmipl.com/crm-portal/api/employee_register`,
            data: newUser,
          });
          
          mutate(`https://sdmipl.com/crm-portal/api/employee/detailes`);
        } catch (error) {
          console.error("Error:", error);
        }
      };
    
    
     
      const handleDelete = async (id) => {
        try {
         const response = await axiosInstance({
            method:'post',
            url:`https://sdmipl.com/crm-portal/api/employee/delete`,
            data:{
              id:id
            }
          });
       
          mutate(`https://sdmipl.com/crm-portal/api/employee/detailes`);
        } catch (err) {
          console.log(err);
        }
      };
    
      
    
      const handleStatus = async(data)=>{
      
        try{
          const response = await axiosInstance({
            method: "post",
            url: `https://sdmipl.com/crm-portal/api/employee/status`,
            data: {
              id: data.id,
              status:!data.status
            },
           
          });
          // console.log("status response",response.data);
          mutate(`https://sdmipl.com/crm-portal/api/employee/detailes`);
        }
        catch(err)
        {
          console.log(err)
        }
      }
return(
    <>
         
          

      <Modal isOpen={CreateIsOpen} backdrop="blur" onOpenChange={CreateOnOpenChange} hideCloseButton>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className={`flex flex-col gap-1 text-center titleForm`}>Create Employee</ModalHeader>
              <ModalBody className={`bgForm`}>
              <form
            className="flex w-full flex-wrap  px-2 py-5 md:py-5 md:px-5 gap-5"
            onSubmit={CreateEmployee}
          >
            <input
              type="text"
              name="name"
              required="required"
              placeholder="Enter Employee Name"
              onChange={handleUpdateValue}
              className={`w-full  px-5 py-2 rounded-lg outline-none formInput shadow-sm shadow-black`}
            />
            <input
              type="email"
              name="email"
              required="required"
              onChange={handleUpdateValue}
              placeholder="Enter Employee Email"
              className={`w-full text-black px-5 py-2 rounded-lg outline-none formInput shadow-sm shadow-black`}
            />
            <input
              type="text"
              name="password"
              required="required"
              onChange={handleUpdateValue}
              placeholder="Enter Employee Password"
              className={`w-full text-black px-5 py-2 rounded-lg outline-none formInput shadow-sm shadow-black`}
            />
            <input
              type="number"
              name="mobile"
              onChange={handleUpdateValue}
              required="required"
              placeholder="Enter Employee Mobile"
              className={`w-full  px-5 py-2 rounded-lg outline-none formInput shadow-sm shadow-black`}
            />
            {/* <input
              type="text"
              name="role"
              required="required"
              onChange={handleUpdateValue}
              placeholder="Enter Employee Role"
              className={`w-full text-black px-5 py-2 rounded-lg outline-none ${Style.formInput}`}
            /> */}
            <select
              className={`w-full  px-5 py-2 rounded-lg outline-none formInput shadow-sm shadow-black`}
              name="role"
              onChange={handleUpdateValue}
              placeholder="Enter Employee Role"
            >
              <option value="choose role">Choose User Role</option>
              <option value="admin">admin</option>
              <option value="employee">Employee</option>
            </select>

            <select
              className={`w-full  px-5 py-2 rounded-lg outline-none formInput shadow-sm shadow-black`}
              name="gender"
              onChange={handleUpdateValue}
            >
              <option value="choose gender">Choose Gender</option>
              <option className="male" value="male">
                Male
              </option>
              <option className="female" value="female">
                Female
              </option>
            </select>

            <div className="flex flex-wrap justify-center gap-3">
              <Button
                type="submit"
                onClick={handleCloseForm}
                className="bg-green-600 hover:bg-green-800 text-white shadow-sm shadow-black"
              >
                Add Employee
              </Button>
              <Button
                onClick={handleCloseForm}
                className="bg-red-600 text-white hover:bg-red-800"
              >
                Cancel
              </Button>
            </div>
          </form>
              </ModalBody>
            
            </>
          )}
        </ModalContent>
      </Modal>
      {/* Create Employee Form with Dialog End */}



      {/* Upload Csv File of Employee Data Start */}
      {/* <Dialog open={openUpload} onClose={handleCloseUpload}>
        <DialogTitle className={`text-center titleForm`}>
          Upload Employee CSV File
        </DialogTitle>
        <DialogContent className={`bgForm`}>
          
          <form
            className="flex w-full flex-wrap text-black p-2 md:p-5 gap-5"
            onSubmit={handleFileSubmit}
          >
            <input
              type="file"
              id="fileInput"
              name="csvFile"
              accept={acceptableCSVFileTypes}
              onChange={handleFile}
              required="required"
              placeholder="Enter Employee Name"
              className={`w-full text-black px-5 bg-white py-2 rounded-lg outline-none formInput`}
            />

            <div className="flex flex-wrap justify-center gap-3">
              <Button
                type="submit"
                onClick={handleCloseUpload}
                className="bg-green-600 hover:bg-green-800 text-white"
              >
                Upload
              </Button>
              <Button
                onClick={handleCloseUpload}
                className="bg-red-600 text-white hover:bg-red-800"
              >
                Cancel
              </Button>
            </div>
          </form>
       
        </DialogContent>
      </Dialog> */}


      <Modal isOpen={UploadIsOpen} backdrop="blur" onOpenChange={UploadOnOpenChange} hideCloseButton>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className={`flex flex-col gap-1 text-center titleForm`}>Upload Employee CSV File</ModalHeader>
              <ModalBody className={`bgForm`}>
              <form
            className="flex w-full flex-wrap text-black p-2 md:p-5 gap-5"
            onSubmit={handleFileSubmit}
          >
            <input
              type="file"
              id="fileInput"
              name="csvFile"
              accept={acceptableCSVFileTypes}
              onChange={handleFile}
              required="required"
              placeholder="Enter Employee Name"
              className={`w-full  px-5 py-2 rounded-lg outline-none formInput shadow-sm shadow-black dark:bg-[#121212] dark:text-white`}
            />

            <div className="flex flex-wrap justify-center gap-3">
              <Button
                type="submit"
                onClick={handleCloseUpload}
                className="bg-green-600 hover:bg-green-800 text-white"
              >
                Upload
              </Button>
              <Button
                onClick={handleCloseUpload}
                className="bg-red-600 text-white hover:bg-red-800"
              >
                Cancel
              </Button>
            </div>
          </form>
              </ModalBody>
            
            </>
          )}
        </ModalContent>
      </Modal>

      {/* Upload Csv File of Employee Data Start */}

      {/* Edit Employee Form with Dialog End */}
      {/* <Dialog open={open} onClose={handleClose}>
        <DialogTitle className={`text-center titleForm`}>
          Edit Form
        </DialogTitle>
        <DialogContent className="bg-slate-900">
          
          <form
            className="flex w-full flex-wrap text-black p-2 md:p-5 gap-5"
          
          >
            <input
              type="text"
              value={editedItem.fullname}
              name="fullname"
              placeholder="Enter Your FullName"
              onChange={(e) =>
                setEditedItem({ ...editedItem, fullname: e.target.value })
              }
              className={`w-full text-black px-5 py-2 rounded-lg focus:bg-gradient-to-r from-sky-200 via-sky-300  to-sky-400 outline-none formInput`}
            />
            <input
              type="email"
              placeholder="Enter Your Email"
              name="email"
              value={editedItem.email}
              onChange={(e) =>
                setEditedItem({ ...editedItem, email: e.target.value })
              }
              className={`w-full text-black px-5 py-2 rounded-lg focus:bg-gradient-to-r from-sky-200 via-sky-300  to-sky-400 outline-none formInput`}
            />
            <input
              type="number"
              name="mobile"
              placeholder="Enter Your Mobile"
              value={editedItem.mobile}
              onChange={(e) =>
                setEditedItem({ ...editedItem, mobile: e.target.value })
              }
              className={`w-full text-black px-5 py-2 rounded-lg focus:bg-gradient-to-r from-sky-200 via-sky-300  to-sky-400 outline-none formInput`}
            />

            <div className="flex flex-wrap justify-center gap-3">
              <Button
                onClick={handleClose}
                className="bg-red-600 text-white hover:bg-red-800"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="bg-green-600 hover:bg-green-800 text-white"
              >
                Update
              </Button>
            </div>
          </form>
          
        </DialogContent>
      </Dialog> */}
      {/* Edit Employee Form with Dialog End */}
      <div className=" p-2 flex flex-col md:flex-row justify-end gap-3 px-5 ">
        <input
          type="search"
          name="search"
          placeholder="Search By Name......"
          className="outline-none bg-white dark:bg-[#27272A] md:w-96 w-full py-2 px-5 rounded-lg shadow-sm shadow-black"
        />

        <div className="flex items-center justify-center gap-5">
          <Tooltip content="Create Employee"  className="bg-gradient-to-tr from-teal-500 to-blue-500">
           
             
              <Button className=" shadow-sm shadow-black bg-gradient-to-tr from-teal-500 to-blue-500" isIconOnly onClick={CreateOnOpen} startContent={ <PersonAdd className="text-white" />}></Button>
            
          </Tooltip>


          <Tooltip content="Upload Employees"  className="bg-gradient-to-tr from-slate-800 to-slate-700">
           

            <Button className="shadow-sm shadow-black  bg-gradient-to-tr from-slate-800 to-slate-700" isIconOnly onClick={UploadOnOpen} startContent={  <CloudUpload className="text-white" /> }></Button>
          </Tooltip>
        </div>
      </div>
      <div className={`w-full  overflow-auto`}>
     
        <Table  >
          <TableHeader >
            
              {TableName.map((data, index) => {
                return (
                  <TableColumn
                    key={index}
                    align="center"
                    className="dark:text-[#cacaca] text-[#27272A] p-4 font-bold"
                  >
                    {data.label}
                  </TableColumn>
                );
              })}
            
          </TableHeader>

          <TableBody >
            {data &&
              data?.employeedata?.map((items, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell align="center" className="dark:text-[#ababab] text-[#27272A]">
                      {items.id}
                    </TableCell>
                    <TableCell align="center" className="dark:text-[#ababab] text-[#27272A]">
                      {items.name}
                    </TableCell>
                    <TableCell align="center" className="dark:text-[#ababab] text-[#27272A]">
                      {items.mobile}
                    </TableCell>
                    <TableCell align="center" className="dark:text-[#ababab] text-[#27272A]">
                      {items.email}
                    </TableCell>
                    <TableCell align="center" className="dark:text-[#ababab] text-[#27272A]">
                      {items.role}
                    </TableCell>
                    <TableCell align="center" className="dark:text-[#ababab] text-[#27272A]">
                      {items.gender}
                    </TableCell>
                    <TableCell align="center" className="dark:text-[#ababab] text-[#27272A]">
                      {items.status === 1 ? (
                        <Button
                          className="bg-green-600 text-white"
                          onClick={() => handleStatus(items)}
                        >
                          Active
                        </Button>
                      ) : (
                        <Button
                          className="bg-red-600 text-white"
                          onClick={() => handleStatus(items)}
                        >
                          Inactive
                        </Button>
                      )}
                    </TableCell>

                    {/* <TableCell align="center">
                      <IconButton onClick={() => handleEdit({ items })}>
                        <Edit className="text-[#222097]" />
                      </IconButton>
                    </TableCell> */}
{/* 
                    <TableCell align="center">
                      <IconButton onClick={() => handleDelete(items.id)}>
                        <Delete className="text-[#ff1616]" />
                      </IconButton>
                    </TableCell> */}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      
      </div>
    </>
);
}
export default EmployeesData;