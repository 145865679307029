
import { Button, Divider, Switch } from "@nextui-org/react";
import { Link, Outlet, useLocation } from "react-router-dom";


import { useEffect, useState } from "react";

import "./index.css"

import { MoonIcon, SunIcon } from "../mode";
import { AccountCircle, AddTask, Analytics, Close, CloudUpload, Coffee, GroupAdd, HowToReg, Login, MenuOpen, School, Tty } from "@mui/icons-material";
const Dashboard = () => {

    const Menus = [
        {
          id: "1",
          label: "Analytics",
          icon: <Analytics size={20}  className="text-white"/>,
          labelLinkClass: "p-2 w-full flex gap-4 justify-start   text-[#fff] rounded-lg ",
          variant:"shadow",
          color:"success",
          href: "/admindashboard/analytics",
        },
        {
          id: "2",
          label: "Employee Data",
          icon: <AccountCircle size={20}  className="text-white" />,
          labelLinkClass:
            "p-2 w-full flex gap-4 justify-start   text-[#fff] rounded-lg ",
          className: " rounded-lg",
          variant:"shadow",
          color:"secondary",
          href: "/admindashboard/employees",
        },
        
        {
          id: "3",
          label: "Upload & Assign Student",
          icon: <CloudUpload  size={23}  className="text-white" />,
          labelLinkClass:
            "p-2 w-full flex gap-4 justify-start ]  text-[#fff] rounded-lg ",
          iconClass:"bg-gradient-to-tl to-sky-400 from-blue-500 shadow-sm shadow-sky-600",
            href: "/admindashboard/uploadassignstd",
          },

        {
          id: "6",
          label: "Assigned Data",
          icon: <HowToReg  size={23}  className="text-white" />,
          labelLinkClass:
            "p-2 w-full flex gap-4 justify-start ]  text-[#fff] rounded-lg ",
          iconClass:"bg-gradient-to-tl to-sky-400 from-blue-500 shadow-sm shadow-sky-600",
            href: "/admindashboard/assigneddata",
          },
          
          {
            id: "4",
            label: "Calling Report",
            icon: <Tty size={23}  className="text-white" />,
            labelLinkClass:
              "p-2 w-full flex gap-4 justify-start   text-[#fff] rounded-lg ",
              variant:"shadow",
              color:"warning",
            href: "/admindashboard/callingreport",
          },
          
          // {
          //   id: "5",
          //   label: "Student Data",
          //   icon: <School size={23}  className="text-white" />,
          //   labelLinkClass:
          //     "p-2 w-full flex gap-4 justify-start   text-[#fff] rounded-lg ",
          //     variant:"shadow",
          //     color:"warning",
          //   href: "/admindashboard/studentsdata",
          // },
          
          {
          id: "15",
          label: "Task",
          icon: <AddTask size={23}  className="text-white"/>,
          labelLinkClass:
            "p-2 w-full flex gap-4 justify-start  text-[#fff] rounded-lg ",
          className: " rounded-lg",
          href: "/admindashboard/task",
          variant:"shadow",
          color:"secondary",
        },
        
        {
          id: "8",
          label: "Employee Break Activity",
          icon: <Coffee size={23}  className="text-white"/>,
          labelLinkClass:
            "p-2 w-full flex gap-4 justify-start  text-[#fff] rounded-lg ",
            iconClass:"bg-gradient-to-tl to-[#ad936d] from-yellow-900 shadow-sm shadow-[#c2a57c",
            variant:"shadow",
          href: "/admindashboard/breakactivity",
        },
        {
          id: "9",
          label: "Employee Login Activity",
          icon: <Login size={23}  className="text-white" />,
          labelLinkClass:
            "p-2 w-full flex gap-4 justify-start  text-[#fff] rounded-lg ",
          href: "/admindashboard/loginactivity",
          variant:"shadow",
          color:"danger",
        },
        {
          id: "10",
          label: "Reference Data",
          icon: <GroupAdd size={23}  className="text-white" />,
          labelLinkClass:
            "p-2 w-full flex gap-4 justify-start  text-[#fff] rounded-lg ",
          className: " rounded-lg",
          href: "/admindashboard/referenceddata",
        },
      
        
      ];

      const [open, setOpen] = useState(false);
      const [isDarkMode, setIsDarkMode] = useState(true);
      
      useEffect(() => {
        const root = window.document.documentElement;
        if (isDarkMode) {
          root.classList.add("dark");
        } else {
          root.classList.remove("dark");
        }
      }, [isDarkMode]);

      const toggleDarkMode = () => {
        setIsDarkMode(!isDarkMode);
      };
      
  return (
    <main className="flex h-screen text-[#282E33] dark:text-[#F1F2F4]">
    {/* Sidebar */}
    <aside
      className={`bg-[#161A1D] transition-all duration-300 min-h-screen  ${
        open ? "w-64" : "w-16"
      }`}
      style={{ zIndex: open ? 50 : 1, position: "fixed", height: "100%" }}
    >
      <div className="text-center w-full p-4">
        <button
          className="text-white focus:outline-none w-full"
          onClick={() => setOpen(!open)}
        >
          {open ? (
            <div className="flex w-full justify-between items-center">
              <h1 className="text-lg">Bano Doctor</h1>
              <Close size={20}/>
            </div>
          ) : (
            <MenuOpen size={26}/>
          )}
        </button>
      </div>

      <Divider />
      <div className={`Sidebarscroll py-2 flex flex-col items-center gap-5 px-2 `} >
        {Menus.map((items, index) => {
          return (
            <div className="flex gap-8 w-full " key={index}>
              {open ? (
                <Link to={items.href} className={items.labelLinkClass} >
                  {items.icon} {items.label}

                  
                </Link>
              ) : (
                <Link to={items.href}  className="w-full  flex justify-center" >
                  
                  <Button variant={items.variant} className={items.iconClass} color={items.color} isIconOnly startContent={items.icon}></Button>
                </Link>
              )}
            </div>
          );
        })}
      </div>   
    </aside>

    {/* Main Content */}
    <main
      className="flex-1 overflow-x-hidden overflow-y-auto bg-[#F1F2F4] dark:bg-[#22272B]  ml-16"
      onClick={() => setOpen(false)}
    >
      <header className="flex items-center justify-between   p-2 text-[#282E33] dark:text-[#F1F2F4]">
        <h1 className=" text-2xl font-semibold">
          Bano Doctor
        </h1>
        <Switch
      defaultSelected
      isIconOnly
      size="lg"
      color="secondary"
      onChange={toggleDarkMode}
      thumbIcon={({ isSelected, className }) =>
        isSelected ? (
         
          <MoonIcon className={className} />
        ) : (
            <SunIcon className={className} />
        )
      }
    >
     
    </Switch>
      </header>

      <div className="p-2">
      <div className=" w-full shadow-black md:p-6 p-1">
      
      <Outlet/>
      {/* {
        console.log(window.location.pathname)
      } */}
      {/* <DataFlow /> */}
    </div>
      </div>
    </main>
  </main>
  );
};

export default Dashboard;
