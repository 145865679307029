import { useEffect, useState } from "react";
import logo from "../../assets/logo.png";
import Style from "./index.module.css";
import { Button, Image, Input, SelectItem } from "@nextui-org/react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import axios from "axios";
import Background from "../../assets/bg.png";

import {
  Check,
  CurrencyRupee,
  Email,
  History,
  Password,
  RemoveRedEyeRounded,
  RemoveRedEyeTwoTone,
  Task,
  UploadFile,
} from "@mui/icons-material";

const Login = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const toggleVisibility = () => setIsVisible(!isVisible);

  const schema = yup.object({
    email: yup
      .string()
      .required("Email is required*")
      .email("Enter Valid Email"),

    password: yup.string().required("Password is required*"),

    CLIENT_ID: yup.string().required("CLient Id is required"),
  });

  const handleSubmit = async (e) => {
    try {
      const response = await axios({
        method: "post",
        url: "https://sdmipl.com/crm-portal/api/login",
        data: e,
      });
      const userData = response.data.data[0];
      const token = response.data.token;

      if (token) {
        if (userData.role == "admin") {
          navigate("/admindashboard/analytics");
        } else {
          alert(response.data.message);

          console.log(response.data.message);
        }
      } else {
        console.log("Token is not exist");
        navigate("/");
      }
      sessionStorage.setItem("user", JSON.stringify(userData));
      sessionStorage.setItem("token", token);
    } catch (err) {
      console.log(err.response);
    }
  };

  return (
    <>
      <div className="w-full flex flex-col min-h-screen  bg-gradient-to-r from-pink-100 to-indigo-200 ">
        <div className="w-full flex flex-col min-h-screen items-center justify-center">
          <div className="w-full max-w-5xl mx-auto bg-white rounded-lg shadow-lg p-8 grid grid-cols-1 md:grid-cols-2 gap-6 ">
            <div>
              <div className="flex justify-center pb-10">
                <Image src={logo} alt="Logo" className="w-32 " />
              </div>
              <span className="mt-6 text-4xl  text-black">Welcome to ...</span>
              <h1 className="bg-clip-text font-semibold text-5xl text-transparent bg-gradient-to-r from-pink-500 to-violet-500">
                {" "}
                Bano Doctor
              </h1>

              <p className="mt-2 text-lg text-gray-700">
                An App to handle your data and employee
              </p>

              <ul className=" grid grid-cols-1 md:grid-cols-2 gap-4 mt-10 space-y-2">
                <li className="flex items-center gap-3 space-x-2">
                  <span className="w-10 h-10 flex justify-center items-center rounded-s-full rounded-br-full rounded-tr-3xl bg-gradient-to-tr from-red-400 to-amber-400">
                    <Task size={30} className="text-white font-bold" />
                  </span>
                  <div className="flex flex-col ">
                    <span className="text-gray-700 font-semibold">Task</span>
                    <span className="text-gray-700 font-semibold">Assign</span>
                  </div>
                </li>
                <li className="flex items-center gap-3 space-x-2">
                  <span className="w-10 h-10 flex justify-center items-center rounded-s-full rounded-br-full rounded-tr-lg bg-gradient-to-tr from-indigo-500 to-purple-500">
                    <History size={26} className="text-white" />
                  </span>

                  <div className="flex flex-col ">
                    <span className="text-gray-700 font-semibold">Call</span>
                    <span className="text-gray-700 font-semibold">History</span>
                  </div>
                </li>
                <li className="flex items-center gap-3 space-x-2">
                  <span className="w-10 h-10 flex justify-center items-center rounded-s-full rounded-br-full rounded-tr-lg bg-gradient-to-tr from-red-400 to-pink-500">
                    <UploadFile size={26} className="text-white" />
                  </span>

                  <div className="flex flex-col ">
                    <span className="text-gray-700 font-semibold">Upload</span>
                    <span className="text-gray-700 font-semibold">CSV</span>
                  </div>
                </li>
                <li className="flex items-center gap-3 space-x-2">
                  <span className="w-9 h-9 flex justify-center items-center rounded-s-full rounded-br-full rounded-tr-3xl bg-gradient-to-tr from-blue-400 to-cyan-400">
                    <Check size={26} className="text-white" />
                  </span>

                  <div className="flex flex-col ">
                    <span className="text-gray-700 font-semibold">Data</span>
                    <span className="text-gray-700 font-semibold">Assign</span>
                  </div>
                </li>
              </ul>
            </div>
            <div className="bg-white rounded-lg shadow-md shadow-gray-400 p-6">
              <h3 className="text-2xl      font-sans font-semibold text-gray-900">
                Login in your account
              </h3>
              <Formik
                onSubmit={handleSubmit}
                initialValues={{
                  email: "",
                  password: "",

                  CLIENT_ID: "7hWJKiyKDvdeJAnlGTQuoXa8eQ4kui0cmRAJergef4f44e5",
                }}
                validationSchema={schema}
              >
                {(formik) => {
                  return (
                    <Form className=" flex flex-col px-5 justify-center  gap-5 bg-white  rounded-lg  ">
                      <h3 className="text-2xl font-medium text-center text-white">
                        Login Here
                      </h3>
                      <Field
                        type="email"
                        name="email"
                        labelPlacement="outside"
                        as={Input}
                        label="Email"
                        color="#323232"
                        value={formik.values.email}
                        placeholder="Enter Your Email"
                        size="lg"
                        className="   rounded-xl placeholder-white"
                        startContent={<Email className="text-gray-400" />}
                        variant="faded"
                      />
                      <ErrorMessage
                        name="email"
                        component="p"
                        className="text-[#ff4141]  -mt-4"
                      />

                      <Field
                        type={isVisible ? "text" : "password"}
                        name="password"
                        label="Password"
                        labelPlacement="outside"
                        as={Input}
                        size="lg"
                        color="#f2f2f2"
                        value={formik.values.password}
                        placeholder="Enter Your password"
                        id="Password"
                        className="   rounded-xl placeholder-white "
                        startContent={<Password />}
                        variant="faded"
                        endContent={
                          <button
                            className="focus:outline-none"
                            type="button"
                            onClick={toggleVisibility}
                          >
                            {isVisible ? (
                              <RemoveRedEyeTwoTone color="gray" />
                            ) : (
                              <RemoveRedEyeRounded color="gray" />
                            )}
                          </button>
                        }
                      />
                      <ErrorMessage
                        name="password"
                        component="p"
                        className="text-[#ff4141]  -mt-4"
                      />

                      <Field
                        type="password"
                        name="CLIENT_ID"
                        hidden
                        value={formik.values.CLIENT_ID}
                        placeholder="Enter Your password"
                        id="CLIENT_ID"
                        className="w-full px-5 py-2 shadow-sm shadow-gray-600 rounded-md text-black outline-none"
                      />
                      <ErrorMessage
                        name="CLIENT_ID"
                        component="p"
                        className="text-red-500 py-0 my-0"
                      />

                      <Button
                        disabled={!(formik.dirty && formik.isValid)}
                        variant="contained"
                        type="submit"
                        className="block w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-white bg-gradient-to-l to-pink-500 from-purple-600 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      >
                        Submit
                      </Button>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
