import { DateRangePicker } from "@nextui-org/date-picker";
import { Button, Select, SelectItem,Table, TableBody, TableCell, TableColumn, TableHeader, TableRow  } from "@nextui-org/react";
import { useEffect, useState } from "react";
import axiosInstance from "../../Auth/axios";
import { Download, Reply } from "@mui/icons-material";

const BetweenDateReport = () => {
  const [EmployeesId, setEmployeesId] = useState(null);
  const [EmployeeData, setEmployeeData] = useState([]);
  const [StartDate,setStartDate] = useState("");
  const [EndDate,setEndDate] = useState("");
  const [AllReportData,setAllReportData] = useState([]);
  const [EmployeeName,setEmployeeName] = useState("");


  //Fetch Employee Details
  useEffect(() => {
    const fetchEmployeesData = async () => {
      try {
        const response = await axiosInstance({
          method: "get",
          url: `https://sdmipl.com/crm-portal/api/employee/detailes`,
        });

        setEmployeeData(response.data.employeedata);
        const EmployeeDataId = response.data.employeedata.map(
          (item) => item.id
        );
        setEmployeesId(EmployeeDataId);
      } catch (err) {
        console.log("Error in EmployeesData:", err.response);
      }
    };

    fetchEmployeesData();
  }, []);

  const HandleEmployeeSelection = (id)=>{
    const EmpId = Array.from(id)[0];
    setEmployeesId(EmpId)

  }


  const handleDateChange = (newDate)=>{
    const {start,end} = newDate;
    const StartDate = `${start.year}/${start.month}/${start.day}`;
    const EndDate = `${end.year}/${end.month}/${end.day}`;
    

    setStartDate(StartDate);
    setEndDate(EndDate);

  }

  const HandleMonthelyReport = async (e) => {
   e.preventDefault()
    try {
      const response = await axiosInstance({
        method: "post",
        url: "https://sdmipl.com/crm-portal/api/employee/view/records-between-dates",
        data: {
          start_date:StartDate,
          end_date:EndDate,
          employee_id:EmployeesId
        },
      });
      
     
    
      const uniqueDates = [...new Set(response.data.data.map((item) => item.calling_updated))];
     

      const FilterData = Array.from(uniqueDates).map((date)=>{
        const dateData = response.data.data.filter((item) => item.calling_updated == date);
        const employeeName = EmployeeData.find((item) => item.id == EmployeesId)?.name ;
        setEmployeeName(employeeName);
        const connectedCalls = dateData.filter((item) => item.call_status == "connected");
        const notConnectedCalls = dateData.filter((item) => item.call_status == "not connected");
        const totalCalls = dateData.length;
        const totalSeconds = connectedCalls.reduce((total, item) => {
          const [hours, minutes, seconds] = item.total_time.split(":").map(Number);
          return total + (hours * 3600) + (minutes * 60) + seconds;
        }, 0);
      
        const hours = Math.floor(totalSeconds / 3600);
        const remainingSecondsAfterHours = totalSeconds % 3600;
        const minutes = Math.floor(remainingSecondsAfterHours / 60);
        const seconds = remainingSecondsAfterHours % 60;
          return{
            date,
            employee_name:employeeName,
            connected_calls: connectedCalls.length,
            not_connected_calls: notConnectedCalls.length,
            total_calls: totalCalls,
            total_duration: `${hours} H: ${minutes} M: ${seconds} S`,
          }
      })
      console.log("Filter",FilterData)

      setAllReportData(FilterData);
    } catch (err) {
      console.log(err);
      alert("There are Some Error to take date contact with developer");
    }
  };


  return (
    <>
      <div>
        <form className="flex flex-col md:flex-row gap-3" onSubmit={HandleMonthelyReport}>
          <DateRangePicker
            showMonthAndYearPickers
            granularity="day"
            label="Stay duration"
            variant="faded"
            className="shadow-sm shadow-black rounded-xl"
            onChange={handleDateChange}
          />

          <Select
            variant="faded"
            label="Select Employee"
            placeholder="Select an Employee"
            className="shadow-sm shadow-black rounded-xl"
            onSelectionChange={HandleEmployeeSelection}
          >
            {EmployeeData.map((item) => (
              <SelectItem key={item.id} value={item.id}>
                {item.name}
              </SelectItem>
            ))}
          </Select>

          <div className="flex gap-3 flex-col sm:flex-row">
            <Button
              className="bg-gradient-to-l to-emerald-500 from-green-400 text-white font-sans font-medium"
              variant="shadow"
              color="success"
              startContent={<Reply />}
             type="submit"
            >
              Get Data
            </Button>
            <Button
              className="bg-gradient-to-l to-cyan-400 from-blue-400 text-white font-sans font-medium"
              variant="shadow"
              color="primary"
              startContent={<Download />}
            >
              Download
            </Button>
          </div>
        </form>

        <Table className="my-3" aria-label="Assigned Data">
                        <TableHeader>
                            <TableColumn>DATE</TableColumn>
                            <TableColumn>NAME</TableColumn>
                            <TableColumn>TOTAL CALLS</TableColumn>
                            <TableColumn>CONNECTED CALLS</TableColumn>
                            <TableColumn>NOT CONNECTED</TableColumn>
                            <TableColumn>TOTAL DURATION</TableColumn>
                        </TableHeader>
                        <TableBody>
                            

                            { AllReportData.map((items,index)=>{
                              return (
                                <TableRow key={index}>
                                <TableCell>{items.date}</TableCell>
                                <TableCell>{EmployeeName}</TableCell>
                                <TableCell>{items.total_calls}</TableCell>
                                <TableCell>{items.connected_calls}</TableCell>
                                <TableCell>{items.not_connected_calls}</TableCell>
                                <TableCell>{items.total_duration}</TableCell>
                               
                            </TableRow>
                              );
                            })}
                        </TableBody>
                    </Table>
      </div>
    </>
  );
};
export default BetweenDateReport;
