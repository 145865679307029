import "./index.css";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Input,
  Tab,
  Tabs,
  Pagination,
  SelectItem,
  Select,
} from "@nextui-org/react";
import useSWR from "swr";
import moment from "moment";
import { useMemo, useState } from "react";
import axiosInstance from "../../Auth/axios";
const columns = [
  {
    key: "name",
    label: "Name",
  },

  {
    key: "date",
    label: "Date",
  },
  {
    key: "login_time",
    label: "Login Time",
  },
  {
    key: "logout_time",
    label: "Logout Time",
  },
];

const LoginActivity = () => {
  const [selected, setSelected] = useState([]);

  const [employeeLoginData, setEmployeeLoginData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [EmployeeID, setEmployeeID] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [tabPage, setTabPage] = useState(1);
  const itemsPerPage = 11;
  const tabItemsPerPage = 11;

  const req = async (url) => {
    try {
      const response = await axiosInstance({
        method: "get",
        url: url,
      });

      console.log("fd", response.data);
      return response.data;
    } catch (err) {}
  };

  const { data, error } = useSWR(
    `https://sdmipl.com/crm-portal/api/get/login/activity`,
    req
  );

  const reqEmp = async (url) => {
    try {
      const response = await axiosInstance({
        method: "get",
        url: url,
      });

      return response.data;
    } catch (err) {
      return err.response;
    }
  };

  const { data: EmployeeData, error: EmployeeError } = useSWR(
    `https://sdmipl.com/crm-portal/api/employee/detailes`,
    reqEmp,
    {
      revalidateInterval: 3000,
    }
  );

  const formatDate = (dateString) => {
    return moment(dateString).format("DD-MM-YYYY");
  };

  const formatTime = (timeString) => {
    return moment(timeString, "HH:mm:ss").format("hh:mm A");
  };

  const handleDateChange = (e) => {
    console.log(e.target)
    setSelectedDate(e.target.value);
  };

  const HandleSelect = (e) => {
    const EmployeeID = Array.from(e)[0];
    setEmployeeID(EmployeeID);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleTabPageChange = (page) => {
    setTabPage(page);
  };

  const filterData = (data) => {
    if (!data) return [];
    let filteredData = data.employee_break || [];

    if (EmployeeID) {
      filteredData = filteredData.filter((item) => item.user_id == EmployeeID);
    }

    if (selectedDate) {
      filteredData = filteredData.filter(
        (item) => moment(item.created_at).format("YYYY-MM-DD") === selectedDate
      );
    }

    return filteredData;
  };

  const filteredData = filterData(data);
  console.log(filteredData);

  // Calculate the data to be displayed for the current page
  const pages = data ? Math.ceil(filteredData.length / itemsPerPage) : 1;

  const currentData = useMemo(() => {
    if (!data) return [];
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    return filteredData.slice(start, end);
  }, [currentPage, filteredData]);

  const tabPages = Math.ceil(filteredData.length / tabItemsPerPage);
  const currentTabData = useMemo(() => {
    const start = (tabPage - 1) * tabItemsPerPage;
    const end = start + tabItemsPerPage;
    return filteredData.slice(start, end);
  }, [tabPage, filteredData]);

  return (
    <>
      <div>
        <div>
          <h1 className="text-center  font-semibold text-2xl">
            Latest Login Activity
          </h1>

          <Select
            aria-label="Assigned Data Employee Selection"
            variant="faded"
            label="Select Employee"
            placeholder="Select an Employee"
            onSelectionChange={HandleSelect}
            className="shadow-sm shadow-black rounded-xl mb-3"
          >
            {EmployeeData &&
              EmployeeData.employeedata.map((item) => (
                <SelectItem key={item.id} value={item.id}>
                  {item.name}
                </SelectItem>
              ))}
          </Select>

          <div className={`Scroll`}>
            <Table
              aria-label="Example static collection table"
              bottomContent={
                <div className="flex w-full justify-center">
                  {filteredData.length > 0 && (
                    <Pagination
                      isCompact
                      showControls
                      showShadow
                      color="secondary"
                      page={currentPage}
                      total={pages}
                      onChange={handlePageChange}
                    />
                  )}
                </div>
              }
            >
              <TableHeader>
                {columns?.map((column, index) => {
                  console.log(columns);
                  return <TableColumn key={index}>{column.label}</TableColumn>;
                })}
              </TableHeader>
              <TableBody emptyContent={"No Data"}>
                {currentData.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>
                      {moment(item.login_time).format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell>
                      {moment(item.login_time, "HH:mm:ss").format("h:mm A")}
                    </TableCell>
                    <TableCell>
                      {item.logout_time
                        ? moment(item.logout_time, "HH:mm:ss").format("h:mm A")
                        : "Not Logout"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>

        <div className="my-4">
          <h1 className="text-center py-2 font-sans font-semibold text-2xl">
            Employees All Login Activity
          </h1>
          <Input
            type="date"
            label="Select Date"
            onChange={handleDateChange}
            variant="faded"
            className="shadow-sm shadow-black mb-2 mt-1 rounded-xl"
            placeholder="date"
          />

          <div className={`px-1 Scroll w-full `}>
            <Table
              aria-label="table"
              isHeaderSticky
              className="w-full "
              bottomContent={
                <div className="flex w-full justify-center">
                  {filteredData.length > 0 && (
                    <Pagination
                      isCompact
                      showControls
                      showShadow
                      color="secondary"
                      page={tabPage}
                      total={tabPages}
                      onChange={handleTabPageChange}
                    />
                  )}
                </div>
              }
            >
              <TableHeader>
                {columns?.map((column, index) => {
                  return <TableColumn key={index}>{column.label}</TableColumn>;
                })}
              </TableHeader>
              <TableBody emptyContent={"No data to display."}>
                {currentTabData.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>
                      {moment(item.login_time).format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell>
                      {moment(item.login_time, "HH:mm:ss").format("h:mm A")}
                    </TableCell>
                    <TableCell>
                      {item.logout_time
                        ? moment(item.logout_time, "HH:mm:ss").format("h:mm A")
                        : "Not Logout"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginActivity;
