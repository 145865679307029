import { Download, Reply } from "@mui/icons-material";
import { DatePicker } from "@nextui-org/date-picker";
import {
  Button,
  Select,
  SelectItem,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";
import moment from "moment/moment";
import { useEffect, useState } from "react";
import axiosInstance  from '../../Auth/axios';
const DataNameReport = () => {

  const [EmployeeData, setEmployeeData] = useState([]);

  const [EmployeesId, setEmployeesId] = useState([]);
  const [EmployeeName, setEmployeeName] = useState("");


  const [DailyReport, setDailyReport] = useState([]);




  useEffect(() => {
    const fetchEmployeesData = async () => {
      try {
        const response = await axiosInstance({
          method: "get",
          url: `https://sdmipl.com/crm-portal/api/employee/detailes`,
        });

        setEmployeeData(response.data.employeedata);
       
      
      } catch (err) {
        console.log("Error in EmployeesData:", err.response);
      }
    };

    fetchEmployeesData();
  }, []);

  const HandleEmployeeSelection = (id)=>{
    const EmpId = Array.from(id)[0];

    setEmployeesId(EmpId)

    const EmployeeName = EmployeeData.filter((item)=>(item.id == EmpId) );

    setEmployeeName(EmployeeName[0].name)

  }

 

  const handleDateSubmit = async (e) => {
    e.preventDefault();
   try{
 
    const response = await axiosInstance({
      method:"post",
      url:`https://sdmipl.com/crm-portal/api/employee/daily-report-filter-records`,
     data:{
      employee_id:EmployeesId
     }
    });

    console.log("My response",response.data.studentsdata)
const Data = response.data.studentsdata;
const transformedData = Data.map(item => {
  const not_called_data = item['0'].find(d => d.status == null)?.total || 0;
  const connected = item['0'].find(d => d.status == 'connected')?.total || 0;
  const not_connected = item['0'].find(d => d.status === 'not connected')?.total || 0;
  const wrong_no = item['0'].find(d => d.status === 'wrong number')?.total || 0;

  // Ensure all values are numbers
  const connectedNumber = typeof connected === 'number' ? connected : 0;
  const notConnectedNumber = typeof not_connected === 'number' ? not_connected : 0;

  const totalData = connectedNumber + notConnectedNumber + wrong_no;

  return {
  
    data_name: item.data_name,
    not_called_data,
    connected: connectedNumber,
    not_connected: notConnectedNumber,
    wrong_number:wrong_no,
    totalData
  };
});

    console.log(transformedData)

    setDailyReport(transformedData);
    
     
   }
   catch(err)
   {
    console.log(err)
   }
    
  };

  return (
    <>
      <div className="text-slate-900 dark:text-neutral-100">
        
        <form onSubmit={handleDateSubmit}>
        <div className="flex gap-2 flex-col sm:flex-row">
        <Select
            variant="faded"
            label="Select Employee"
            placeholder="Select an Employee"
            className="shadow-sm shadow-black rounded-xl"
            onSelectionChange={HandleEmployeeSelection}
          >
            {EmployeeData.map((item) => (
              <SelectItem key={item.id} value={item.id}>
                {item.name}
              </SelectItem>
            ))}
          </Select>
          <div className="flex gap-3 flex-col sm:flex-row">
            <Button
              className="bg-gradient-to-l to-emerald-500 from-green-400 text-white font-sans font-medium"
              variant="shadow"
              color="success"
              startContent={<Reply />}
             type="submit"
            >
              Get Data
            </Button>
            <Button
              className="bg-gradient-to-l to-cyan-400 from-blue-400 text-white font-sans font-medium"
              variant="shadow"
              color="primary"
              startContent={<Download />}
            >
              Download
            </Button>
          </div>
        </div>
        </form>


        <h1 className=" text-2xl text-center mt-5  uppercase">{EmployeeName}</h1>
        <Table className="my-4" aria-label="Example static collection table">
          <TableHeader>
         
            <TableColumn className="font-bold">DATA NAME</TableColumn>
            <TableColumn className="font-bold">NOT CALLED</TableColumn>
            <TableColumn className="font-bold">CONNECTED</TableColumn>
            <TableColumn className="font-bold">NOT CONNECTED</TableColumn>
            <TableColumn className="font-bold">WRONG NO.</TableColumn>
            <TableColumn className="font-bold">TOTAL CALL</TableColumn>
            
          </TableHeader>
          <TableBody>
          
             


            {
             Array.from(DailyReport).map((items,index)=>{
              return <TableRow key={index}>
            
              <TableCell>{items.data_name}</TableCell>
              <TableCell>{items.not_called_data}</TableCell>
              <TableCell>{items.connected}</TableCell>
              <TableCell>{items.not_connected}</TableCell>
              <TableCell>{items.wrong_number}</TableCell>
              <TableCell>{items.totalData}</TableCell>
            </TableRow>
             })
            }
          </TableBody>
        </Table>
      </div>
    </>
  );
};
export default DataNameReport;
