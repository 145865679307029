import { Download, Reply } from "@mui/icons-material";
import { DatePicker } from "@nextui-org/date-picker";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";
import moment from "moment/moment";
import { useEffect, useState } from "react";
import axiosInstance  from '../../Auth/axios';
const SingleDateReport = () => {
  // const [selectedDate, setSelectedDate] = useState(new Date());
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [employeeData, setEmployeeData] = useState([]);
  const [todayData, setTodayData] = useState([]);
  const [todayTotalTime, setTodayTotalTime] = useState("");
  const [EmployeesId, setEmployeesId] = useState([]);
  const [allReportData, setAllReportData] = useState([]);

  //Modal Data States
  const [visible, setVisible] = useState(false);

  const [ViewModalData, setViewModalData] = useState([]);
  const [EmployeeName, setEmployeeName] = useState("");

  const [DailyReport, setDailyReport] = useState([]);

  let totalTodayDurration = 0;

  const [date, setDate] = useState(null);



  const [selectedDate, setSelectedDate] = useState(null);


  useEffect(() => {
    const fetchEmployeesData = async () => {
      try {
        const response = await axiosInstance({
          method: "get",
          url: `https://sdmipl.com/crm-portal/api/employee/detailes`,
        });

        setEmployeeData(response.data.employeedata);
        const EmployeeDataId = response.data.employeedata.map(
          (item) => item.id
        );
        setEmployeesId(EmployeeDataId);
      } catch (err) {
        console.log("Error in EmployeesData:", err.response);
      }
    };

    fetchEmployeesData();
  }, []);


  const handleDateChange = (newDate) => {

    console.log(newDate)
    setSelectedDate(newDate)
    
    if (newDate) {
      const formattedDate = `${newDate.year}/${newDate.month}/${newDate.day}`;
     const FormatDate= moment(formattedDate).format("YYYY-MM-DD")
     console.log(FormatDate);
      setDate(FormatDate);
    }
  };

  const handleDateSubmit = async (e) => {
    
    e.preventDefault();
   try{
    console.log(date);
    const response = await axiosInstance({
      method:"post",
      url:`https://sdmipl.com/crm-portal/api/get/daily-report-filter-records`,
     data:{
      date_value:date
     }
    });

    console.log("My response",response.data.studentsdata)
const Data = response.data.studentsdata;
const transformedData = Data.map(item => {
  
  const connected = item['0'].find(d => d.status == 'connected')?.total || 0;
  const not_connected = item['0'].find(d => d.status === 'not connected')?.total || 0;
  const wrong_no = item['0'].find(d => d.status === 'wrong number')?.total || 0;

  // Ensure all values are numbers
  const connectedNumber = typeof connected === 'number' ? connected : 0;
  const notConnectedNumber = typeof not_connected === 'number' ? not_connected : 0;

  const totalData = connectedNumber + notConnectedNumber;

  return {
    employee: item.employee,
    connected: connectedNumber,
    not_connected: notConnectedNumber,
    wrong_number:wrong_no,
    totalData
  };
});

    console.log(transformedData)

    setDailyReport(transformedData);
    
     
   }
   catch(err)
   {
    console.log(err)
   }
    
  };

  return (
    <>
      <div className="text-slate-900 dark:text-neutral-100">
        
        <form onSubmit={handleDateSubmit}>
        <div className="flex gap-2 flex-col sm:flex-row">
          <DatePicker
            showMonthAndYearPickers
            label="Select Date"
            labelPlacement="inside"
            value={selectedDate}
            onChange={handleDateChange}
            className="shadow-sm shadow-black rounded-xl "
            variant="faded"
          />
          <div className="flex gap-3 flex-col sm:flex-row">
            <Button
              className="bg-gradient-to-l to-emerald-500 from-green-400 text-white font-sans font-medium"
              variant="shadow"
              color="success"
              startContent={<Reply />}
             type="submit"
            >
              Get Data
            </Button>
            <Button
              className="bg-gradient-to-l to-cyan-400 from-blue-400 text-white font-sans font-medium"
              variant="shadow"
              color="primary"
              startContent={<Download />}
            >
              Download
            </Button>
          </div>
        </div>
        </form>
        <Table className="my-4" aria-label="Example static collection table">
          <TableHeader>
          <TableColumn className="font-bold">DATE</TableColumn>
            <TableColumn className="font-bold">NAME</TableColumn>
            <TableColumn className="font-bold">NOT CALLED</TableColumn>
            <TableColumn className="font-bold">CONNECTED</TableColumn>
            <TableColumn className="font-bold">NOT CONNECTED</TableColumn>
            <TableColumn className="font-bold">WRONG NO.</TableColumn>
            <TableColumn className="font-bold">TODAY CALL</TableColumn>
            
          </TableHeader>
          <TableBody>
          
             


            {
             Array.from(DailyReport).map((items,index)=>{
              return <TableRow key={index}>
              <TableCell>{date}</TableCell>
              <TableCell>{items.employee}</TableCell>
              <TableCell>{items.not_called_data}</TableCell>
              <TableCell>{items.connected}</TableCell>
              <TableCell>{items.not_connected}</TableCell>
              <TableCell>{items.wrong_number}</TableCell>
              <TableCell>{items.totalData}</TableCell>
            </TableRow>
             })
            }
          </TableBody>
        </Table>
      </div>
    </>
  );
};
export default SingleDateReport;
