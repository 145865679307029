import "./Style.module.css"
const Loading = ()=>{
return(
    <>
   <div className={`🤚`}>
	<div className="👉"></div>
	<div className="👉"></div>
	<div className="👉"></div>
	<div className="👉"></div>
	<div className="🌴"></div>		
	<div className="👍"></div>
</div>
    </>
);
}
export default Loading;