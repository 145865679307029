import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Login from "./Components/Login";
import Dashboard from "./Components/Dashboard";
import Analytics from "./Components/Dashboard/Analytics";
import EmployeesData from "./Components/Dashboard/EmployeesData";
import UploadAssignStdData from "./Components/Dashboard/UploadAssignData";
import CallingReport from "./Components/Dashboard/CallingReport";
import AssginedData from "./Components/Dashboard/AssignedData";
import Task from "./Components/Dashboard/Task";
import BreakActivity from "./Components/Dashboard/BreakActivity";
import LoginActivity from "./Components/Dashboard/LoginActivity";
import ReferencedData from "./Components/Dashboard/ReferencedData";


const router = createBrowserRouter([
  {
    path:"/",
    element:<Login />
  },
  {
    path:"/admindashboard",
    element:<Dashboard />,
    children:[
      {
        path:"/admindashboard/analytics",
        element:<Analytics />
      },
      {
        path:"/admindashboard/employees",
        element:<EmployeesData />
      },
      {
        path:"/admindashboard/uploadassignstd",
        element:<UploadAssignStdData />
      },
      {
        path:"/admindashboard/assigneddata",
        element:<AssginedData />
      },
    
      {
        path:"/admindashboard/callingreport",
        element:<CallingReport />
      },
     
      {
        path:"/admindashboard/task",
        element:<Task />
      },
      {
        path:"/admindashboard/breakactivity",
        element:<BreakActivity />
      },
      {
        path:"/admindashboard/loginactivity",
        element:<LoginActivity />
      },
      
      {
        path:"/admindashboard/referenceddata",
        element:<ReferencedData />
      },
    ]
  }
]);

const App = ()=>{

return <RouterProvider router={router}/>
}
export default App;