import { Button, Select, SelectItem, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from "@nextui-org/react";
import { useEffect, useState } from "react";
import axiosInstance from "../../Auth/axios";
import { Check, Reply } from "@mui/icons-material";

const AssginedData = () => {
    // State variables for assigned data section
    const [EmployeesId, setEmployeesId] = useState(null);
    const [EmployeeData, setEmployeeData] = useState([]);
    const [EmployeeName, setEmployeeName] = useState("");
    const [AssignedData, setAssginedData] = useState([]);

    // State variables for reassigned data section
    const [ReassignEmpID, setResignEmpID] = useState(null);
    const [AssignedEmpID, setAssignedEmpID] = useState(null);
    const [DataName, setDataName] = useState([]);
    const [DataCount, setDataCount] = useState(0);
    const [ReassignDataName, setReassignDataName] = useState("");

    // Fetch employee data when component mounts
    useEffect(() => {
        const fetchEmployeesData = async () => {
            try {
                const response = await axiosInstance({
                    method: "get",
                    url: `https://sdmipl.com/crm-portal/api/employee/detailes`,
                });
                setEmployeeData(response.data.employeedata);
            } catch (err) {
                console.log("Error in EmployeesData:", err.response);
            }
        };
        fetchEmployeesData();
    }, []);

    // Handle form submission to get assigned data for a specific employee
    const handleGetAssignedData = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosInstance({
                method: "post",
                url: "https://sdmipl.com/crm-portal/api/get/employee",
                data: { id: EmployeesId },
            });
            const data = EmployeeData.find(item => item.id == EmployeesId)?.name || "";
            setEmployeeName(data);
            setAssginedData(response.data);
        } catch (err) {
            console.log("Error in AssignedData:", err.response);
        }
    };

    // Handle selection change for employee dropdown
    const HandleSelect = (e) => {
        const EmployeeID = Array.from(e)[0];
        setEmployeesId(EmployeeID);
    };

    // Fetch data names when AssignedEmpID changes
    useEffect(() => {


        

        if (AssignedEmpID) {
            fetchDataName();
        }
    }, [AssignedEmpID]);

    // Fetch data names for reassignment
    const fetchDataName = async () => {
        try {
            const response = await axiosInstance({
                method: "post",
                url: "https://sdmipl.com/crm-portal/api/get/dataname-filter-records",
                data: { employee_id: AssignedEmpID },
            });
            setDataName(response.data.studentsdata);
        } catch (err) {
            console.log("Error in FreshData:", err.response);
        }
    };

    const HandleAssignedEmpID = (e)=>{
        const EmployeeID = Array.from(e)[0];
        setAssignedEmpID(EmployeeID);
        
    }
    const HandleReassignedEmpID = (e)=>{
        const EmployeeID = Array.from(e)[0];
        setResignEmpID(EmployeeID);
    }
    const HandleReassignedDataName = (e)=>{
        const DataName = Array.from(e)[0];
        setReassignDataName(DataName);
    }
    const HandleReassignedDataCount = (e)=>{
        const DataCount = Array.from(e)[0];
        setDataCount(DataCount);
    }


    const HandleReassignData =async (e)=>{
        e.preventDefault();
        const data = {
          assigned_emp_id:Number(AssignedEmpID),
          reassign_emp_id:Number(ReassignEmpID),
          data_name:ReassignDataName,
          data_count:DataCount
          
      }
      console.log("Assigned ",data);
      try{
          const response = await axiosInstance({
              method:"post",
              url:"https://sdmipl.com/crm-portal/api/reassign-student-data-to-employee",
              data:{
                  assigned_emp_id:AssignedEmpID,
                  reassign_emp_id:ReassignEmpID,
                  data_name:ReassignDataName,
                  data_count:DataCount
                  
              }
         
          });
          console.log(response);
      }
      catch(err)
      {
          console.log(err)
      }
      }

      

    return (
        <>
            <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4">
                {/* Assigned Data Section */}
                <div className="col-span-1 border-2 border-double border-[#e3e5e7] dark:border-[#38383c] shadow-sm shadow-black rounded-lg px-4 py-2">
                    <h2 className="text-center font-sans font-medium text-xl mt-2 mb-5">Assigned Data</h2>
                    <form onSubmit={handleGetAssignedData} className="flex gap-2 flex-col sm:flex-row">
                        <Select
                            aria-label="Assigned Data Employee Selection"
                            variant="faded"
                            label="Select Employee"
                            placeholder="Select an Employee"
                            onSelectionChange={HandleSelect}
                            className="shadow-sm shadow-black rounded-xl"
                        >
                            {EmployeeData.map((item) => (
                                <SelectItem key={item.id} value={item.id}>
                                    {item.name}
                                </SelectItem>
                            ))}
                        </Select>
                        <Button
                            className="bg-gradient-to-l to-emerald-500 from-green-400 text-white font-sans font-medium"
                            variant="shadow"
                            color="success"
                            startContent={<Reply />}
                            type="submit"
                        >
                            Get Data
                        </Button>
                    </form>

                    <Table className="my-3" aria-label="Assigned Data">
                        <TableHeader>
                            <TableColumn>NAME</TableColumn>
                            <TableColumn>TOTAL DATA</TableColumn>
                            <TableColumn>CALLED DATA</TableColumn>
                            <TableColumn>NOT CALLED</TableColumn>
                            <TableColumn>CONNECTED</TableColumn>
                            <TableColumn>NOT CONNECTED</TableColumn>
                        </TableHeader>
                        <TableBody>
                            <TableRow>
                                <TableCell>{EmployeeName}</TableCell>
                                <TableCell>{AssignedData.totalcount}</TableCell>
                                <TableCell>{AssignedData.olddatacount}</TableCell>
                                <TableCell>{AssignedData.freshcount}</TableCell>
                                <TableCell>{AssignedData.connected}</TableCell>
                                <TableCell>{AssignedData.notconnected}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>

                {/* Reassigned Data Section */}
                <div className="col-span-1 border-2 border-double border-[#e3e5e7] dark:border-[#38383c] shadow-sm shadow-black rounded-lg px-4 py-2">
                    <h2 className="text-center font-sans font-medium text-xl mt-2 mb-5">Reassigned Data</h2>
                    <form className="flex flex-col gap-4" onSubmit={HandleReassignData}>
                        <Select
                            aria-label="Assigned Employee Data"
                            variant="faded"
                            label="Select Employee"
                            placeholder="Select Assigned Employee"
                            onSelectionChange={HandleAssignedEmpID}
                            className="shadow-sm shadow-black rounded-xl"
                        >
                            {EmployeeData.map((item) => (
                                <SelectItem key={item.id} value={item.id}>
                                    {item.name}
                                </SelectItem>
                            ))}
                        </Select>

                        <Select
                            aria-label="Reassigned Employee Data"
                            variant="faded"
                            label="Select Reassign Employee"
                            placeholder="Select an Employee"
                            onSelectionChange={HandleReassignedEmpID}
                            className="shadow-sm shadow-black rounded-xl"
                        >
                            {EmployeeData.map((item) => (
                                <SelectItem key={item.id} value={item.id}>
                                    {item.name}
                                </SelectItem>
                            ))}
                        </Select>

                        <Select
                            aria-label="Select Data Name"
                            variant="faded"
                            label="Select Data Name"
                            placeholder="Select a Data Name"
                            onSelectionChange={HandleReassignedDataName}
                            className="shadow-sm shadow-black rounded-xl"
                        >
                            {DataName.map((item) => (
                                <SelectItem key={item.data_name} value={item.data_name}>
                                    {item.data_name}
                                </SelectItem>
                            ))}
                        </Select>

                        <Select
                            aria-label="Select Data Value"
                            variant="faded"
                            label="Select Data Value"
                            placeholder="Select Data Value"
                            onSelectionChange={HandleReassignedDataCount}
                            className="shadow-sm shadow-black rounded-xl"
                        >
                            <SelectItem key={10} value={10}>
                                10
                            </SelectItem>
                            <SelectItem key={20} value={20}>
                                20
                            </SelectItem>
                            <SelectItem key={30} value={30}>
                                30
                            </SelectItem>
                            <SelectItem key={40} value={40}>
                                40
                            </SelectItem>
                            <SelectItem key={50} value={50}>
                                50
                            </SelectItem>
                            <SelectItem key={60} value={60}>
                                60
                            </SelectItem>
                            <SelectItem key={70} value={70}>
                                70
                            </SelectItem>
                            <SelectItem key={80} value={80}>
                                80
                            </SelectItem>
                            <SelectItem key={90} value={90}>
                                90
                            </SelectItem>
                            <SelectItem key={100} value={100}>
                                100
                            </SelectItem>
                            <SelectItem key={130} value={130}>
                                130
                            </SelectItem>
                            <SelectItem key={150} value={150}>
                                150
                            </SelectItem>
                            <SelectItem key={200} value={200}>
                                200
                            </SelectItem>
                        </Select>

                        <Button
                            className="my-3 bg-gradient-to-l to-cyan-400 from-blue-400 text-white font-sans font-medium"
                            variant="shadow"
                            type="submit"
                            color="primary"
                            startContent={<Check />}
                        >
                            Reassigned
                        </Button>
                    </form>
                </div>
            </div>
        </>
    );
}
export default AssginedData;
