import './index.css';

import {
  Accordion,
  AccordionItem,
  Avatar,
  AvatarGroup,
  AvatarIcon,
  Button,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Input,
  Progress,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Tabs,
  
} from "@nextui-org/react";
import React, { useState,useEffect } from "react";
import useSWR, { mutate } from "swr";
import moment from "moment";
import axiosInstance from '../../../Auth/axios';


const TaskReport = () => {
  const [selected, setSelected] = useState([]);

  const [employeeReportData, setEmployeeReportData] = useState([]);




const [selectedDate, setSelectedDate] = useState(null);

  const reqEmployee = async (url) => {
    try {
      const response = await axiosInstance({
        method: "get",
        url: url,
      });
      return response.data;
    } catch (err) {
      return err.response;
    }
  };

  const { data: EmployeeData, error } = useSWR(
     "https://sdmipl.com/crm-portal/api/get/task",
    reqEmployee,
    {
      revalidateInterval: 3000,
    }
  );


  const onTabClick = async (selectedId) => {
    setSelected([selectedId]);
    
    try {
      const response = await axiosInstance({
        method: "post",
        url: "https://sdmipl.com/crm-portal/api/emp/task",
        data: {
          task_id: selectedId,
        },
      });
     
      
        
      setEmployeeReportData(response.data);

      
     
          
       

    } catch (err) {
      console.log(err);
    }
  };

   const filteredEmployeeData = EmployeeData?.Employee.filter(
     (employee) =>
       !selectedDate || moment(employee.date).isSame(selectedDate, "day")
   );

    const resetDateFilter = () => {
      setSelectedDate("");
      
      mutate();
    };
   
const handleDateChange = (e)=>{setSelectedDate(e.target.value);}

const getProgressColor = (value) => {
  if (value < 25) {
    return "danger";
  } else if (value < 50) {
    return "warning";
  } else if (value < 75) {
    return "secondary";
  } else if (value > 75) {
    return "primary";
  } else if (value === 100) {
    return "success";
  } else {
    return "default";
  }
};




  return (
    <>
    
      <div className="flex flex-col w-full " style={{ height: "calc(100vh - 150px)", overflow: "auto" }}>
        <div className=" flex gap-2 items-center">
          <Input
            type="date"
            isClearable
            variant='faded'
            className='shadow-sm shadow-black rounded-xl'
            label="Search by date..."
            placeholder="Search by Name..."
            onChange={handleDateChange}
            
          />
          <Button color="primary" variant="shadow" className="bg-gradient-to-l to-cyan-400 from-blue-400 text-white font-sans font-medium"  onClick={resetDateFilter}>
            Show All
          </Button>
        </div>
        <div
          className="flex flex-col sm:flex-row mt-4 w-full "
          style={{ height: "calc(100% - 70px)", overflow: "auto" }}
        >
          <Tabs
            aria-label="Options"
            onSelectionChange={onTabClick}
            color="primary"
            selectedKey={selected[0]}
            variant='bordered'
            classNames={{
              tabList:
                "flex-row  sm:flex-col h-[calc(100%-0px)]   gap-2 w-full overflow-auto rounded-l-none  rounded-r-lg  py-3 ",
              cursor: "w-full ",
              tab: "w-full px-1 h-auto  flex flex-row sm:flex-col items-start justify-start   ",
              tabContent: " text-black",
            }}
          >
            {filteredEmployeeData &&
              filteredEmployeeData.map((employee, index) => {
                return (
                  <Tab
                    key={employee.id}
                    title={
                      <div className="w-full">
                        <Card className=" rounded-md ">
                          <CardHeader className="flex gap-3 ">
                            <div className="flex flex-col text-start">
                              <p
                                className={`text-lg font-bold sm:w-80 w-[150px] overflow-auto TaskScroll`}
                              >
                                {employee.task}
                              </p>
                              <p className="text-small text-default-500">
                                Assign By {employee.assignby}
                              </p>
                              <p className="text-small text-bold text-default-500">
                                Date:{" "}
                                {moment(employee.date).format("DD-MM-YYYY")}
                              </p>
                            </div>
                          </CardHeader>
                          
                        </Card>
                      </div>
                    }
                    className="w-full  "
                  >
                    <div className={`p-1 Scroll w-full  `}>
                      <Accordion
                        variant="shadow"
                        color="primary"
                        className="w-full "
                      >
                        <AccordionItem
                          className="w-full "
                          key="1"
                          aria-label="Accordion 1"
                          title={
                            <div className="grid grid-cols-1  gap-4 md:grid-cols-1">
                              <div>
                                <b className="text-justify">{employee.task}</b>
                                <p style={{ fontSize: "15px" }}>
                                  Assign by {employee.assignby}
                                </p>
                              </div>
                              <div>
                                <p style={{ fontSize: "15px" }}>
                                  Date to Complete Task
                                </p>
                                <b style={{ fontSize: "15px" }}>
                                  {moment(employee.duration_date).format(
                                    "DD-MM-YYYY"
                                  )}{" "}
                                  {moment(
                                    employee.duration_time,
                                    " HH:mm"
                                  ).format(" h:mm A")}
                                </b>
                              </div>
                            </div>
                          }
                        >
                          <div className="w-full flex flex-col gap-5  p-2 rounded-md">
                            <div className=" w-full  p-2 rounded-md shadow-sm shadow-black">
                              <b>Task Description</b>
                              <div
                                className="text-justify "
                                dangerouslySetInnerHTML={{
                                  __html: employee.description,
                                }}
                              />
                            </div>

                            <Table
                              aria-label="table"
                              isHeaderSticky
                              className="w-full "
                            >
                              <TableHeader>
                                <TableColumn>EMPLOYEE NAME</TableColumn>
                                <TableColumn>STATUS</TableColumn>
                                <TableColumn>PROGRESS</TableColumn>
                                <TableColumn>REPORT SUBMIT DATE</TableColumn>
                                <TableColumn>REPORT SUMMERY</TableColumn>
                              </TableHeader>
                              <TableBody>
                                {employeeReportData &&
                                  employeeReportData?.employeedata?.map(
                                    (items, index) => {
                                      return (
                                        <TableRow key={index}>
                                          <TableCell>
                                            {items.user_name}
                                          </TableCell>
                                          <TableCell>
                                            {items?.work_status || "not start"}
                                          </TableCell>
                                          <TableCell>
                                            <Progress
                                              isStriped
                                              color={getProgressColor(
                                                items?.work_progress || 0
                                              )}
                                              aria-label="Loading..."
                                              value={items?.work_progress || 0}
                                              className="font-bold text-black"
                                              showValueLabel={true}
                                            />
                                          </TableCell>
                                          <TableCell>
                                            {items?.submittedDate ||
                                              "Not Submited"}
                                          </TableCell>
                                          <TableCell>
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  items?.report_summery ||
                                                  "Not Submited",
                                              }}
                                            />
                                          </TableCell>
                                        </TableRow>
                                      );
                                    }
                                  )}
                              </TableBody>
                            </Table>
                          </div>
                        </AccordionItem>
                      </Accordion>
                    </div>
                  </Tab>
                );
              })}
          </Tabs>
        </div>
      </div>

     
      





      
    </>
  );
};
export default TaskReport;
