import './index.css';
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Tab,
  Tabs,
} from "@nextui-org/react";
import useSWR from "swr";
import moment from "moment";
import { useState } from "react";
import axiosInstance from '../../Auth/axios';
const columns = [
  {
    key: "name",
    label: "Name",
  },
  {
    key: "break_type",
    label: "Break Type",
  },
  {
    key: "date",
    label: "Date",
  },
  {
    key: "start_time",
    label: "Start Time",
  },
  {
    key: "end_time",
    label: "End Time",
  },
  {
    key: "total_time",
    label: "Total Duration",
  },
];

const BreakActivity = () => {
  const [selected, setSelected] = useState([]);

  const [employeeBreakData, setEmployeeBreakData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [employeeName, setEmployeeName] = useState("");
  const [employeeId, setEmployeeData] = useState([]);

  const req = async (url) => {
    try {
      const response = await axiosInstance({
        method: "get",
        url: url,
      });
      
      setEmployeeData(response.data.employee_break);

      return response.data;
    } catch (err) {}
  };

  const { data, error } = useSWR(
    `https://sdmipl.com/crm-portal/api/get/break/report`,
    req
  );

  const reqEmp = async (url) => {
    try {
      const response = await axiosInstance({
        method: "get",
        url: url,
      });
      console.log("employee", response.data);
      return response.data;
    } catch (err) {
      return err.response;
    }
  };

  const { data: EmployeeData, error: EmployeeError } = useSWR(
    `https://sdmipl.com/crm-portal/api/employee/detailes`,
    reqEmp,
    {
      revalidateInterval: 3000,
    }
  );

  const formatDate = (dateString) => {
    return moment(dateString).format("DD-MM-YYYY");
  };

  const formatTime = (timeString) => {
    return moment(timeString, "HH:mm:ss").format("hh:mm A");
  };

  const onTabClick = async (selectedId) => {
    setSelected([selectedId]);
console.log(selectedId)
    try {
      const response = await axiosInstance({
        method: "post",
        url: `https://sdmipl.com/crm-portal/api/get/employee/break/report`,
        data: {
          user_id: selectedId,
        },
      });
      console.log("Selected Emp",response.data)
      setEmployeeBreakData(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  return (
    <>
      <div className="p-2">
        <div className="shadow-sm shadow-black rounded-xl">
          <h1 className="text-center py-2 font-sans font-semibold text-2xl">
            Latest Data
          </h1>
          <div className={`Scroll`}>
            <Table aria-label="Example static collection table">
              <TableHeader>
                {columns?.map((column, index) => {
                  return (
                    <TableColumn className="bg-blue-600 text-white">
                      {column.label}
                    </TableColumn>
                  );
                })}
              </TableHeader>
              <TableBody emptyContent={"No Data"}>
                {data &&
                  data.employee_break.map((item, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>{item.break_type}</TableCell>
                        <TableCell>
                          {moment(item.date).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell>
                          {moment(item.start_time, " HH:mm").format(" h:mm A")}
                        </TableCell>
                        <TableCell>
                          {moment(item.end_time, " HH:mm").format(" h:mm A")}
                        </TableCell>
                        <TableCell>{item.total_time}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </div>
        </div>

        <div className="my-4">
          <h1 className="text-center py-2 font-sans font-semibold text-2xl">
            Employees All Data
          </h1>
          <div
            className="flex flex-col sm:flex-row   shadow-sm shadow-black rounded-lg py-3 pr-3 w-full "
            style={{ height: "calc(100vh - 125px)", overflow: "auto" }}
          >
            <Tabs
              aria-label="Options"
              onSelectionChange={onTabClick}
              color="primary"
              selectedKey={selected[0]}
              classNames={{
                tabList:
                  "flex-row  sm:flex-col h-[calc(100%-0px)]   gap-2 w-full overflow-auto rounded-l-none  rounded-r-lg   ",
                cursor: "w-full ",
                tab: "w-full px-1 h-auto flex flex-row sm:flex-col items-start justify-start   ",
              }}
            >
              {EmployeeData &&
                EmployeeData.employeedata.map((employee, index) => {
                  return (
                    <Tab
                      key={employee.id}
                      title={employee.name}
                      className="w-full  "
                    >
                      <div className={`px-1  Scroll w-full `}>
                        <Table
                          aria-label="table"
                          isHeaderSticky
                          className="w-full "
                        >
                          <TableHeader>
                            {columns?.map((column, index) => {
                              return (
                                <TableColumn className="bg-blue-600 text-white">
                                  {column.label}
                                </TableColumn>
                              );
                            })}
                          </TableHeader>
                          <TableBody emptyContent={"No data to display."}>
                            {employeeBreakData &&
                              employeeBreakData?.employee_break?.map(
                                (item, index) => {
                                  return (
                                    <TableRow>
                                      <TableCell>{item.name}</TableCell>
                                      <TableCell>{item.break_type}</TableCell>
                                      <TableCell>
                                        {moment(item.date).format("DD-MM-YYYY")}
                                      </TableCell>
                                      <TableCell>
                                        {moment(
                                          item.start_time,
                                          " HH:mm"
                                        ).format(" h:mm A")}
                                      </TableCell>
                                      <TableCell>
                                        {moment(item.end_time, " HH:mm").format(
                                          " h:mm A"
                                        )}
                                      </TableCell>
                                      <TableCell>{item.total_time}</TableCell>
                                    </TableRow>
                                  );
                                }
                              )}
                          </TableBody>
                        </Table>
                      </div>
                    </Tab>
                  );
                })}
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
};

export default BreakActivity;
