import { useEffect, useState } from "react";
import Style from "./index.module.css";
import useSWR from "swr";

import axiosInstance from "./../../Auth/axios";
import { HowToReg, MobileFriendly, Padding, Person3Rounded, PhoneDisabled, Repartition, Storage } from "@mui/icons-material";
import Loading from "../Loading";
import {BarChart, PieChart} from "react-gifted-charts";

const professionStyles = {
  "connected": {
    color: "bg-gradient-to-tl from-green-700 to-green-500", // Changed to green
    icon: <MobileFriendly className="text-white" />
  },
  "not connected": {
    color: "bg-gradient-to-tl from-red-700 to-red-500", // Changed to red
    icon: <PhoneDisabled className="text-white" />
  },
  "wrong number": {
    color: "bg-gradient-to-tl from-orange-700 to-orange-500", // Changed to orange
    icon: <PhoneDisabled className="text-white" />
  },
  "null": {
    color: "bg-gradient-to-tl from-gray-700 to-gray-500", // Changed to gray
    icon: <Storage className="text-white" />
  },
  "interested": {
    color: "bg-gradient-to-tl from-cyan-700 to-cyan-500", // Changed to cyan
    icon: <Storage className="text-white" />
  },
  "not interested": {
    color: "bg-gradient-to-tl from-pink-700 to-pink-500", // Changed to pink
    icon: <Storage className="text-white" />
  },
  "Default": {
    color: "bg-gradient-to-tl from-gray-300 to-gray-600", // Changed to gray
    icon: <Storage className="text-white" />
  }
};


const Analytics = () => {
  const [totalCount, setTotalCount] = useState(0);
  const [assignCount, setAssignCount] = useState(0);
  const [totalNotAssignCount, setTotalNotAssignCount] = useState(0);
  const [IsLoading, setIsLoading] = useState(true);

  const req = async (url) => {
    try {
      const response = await axiosInstance.get(url);
      setIsLoading(false);
      console.log(response.data)
      
      return response.data;
    } catch (err) {
      console.log(err);
      setIsLoading(true);
    }
  };

  const { data, error } = useSWR(
    "https://sdmipl.com/crm-portal/api/total/count",
    req
  );

 

  const fetchTotalAssignData = async () => {
    try {
      const response = await axiosInstance({
        method: "get",
        url: "https://sdmipl.com/crm-portal/api/assign/count",
      });

      setAssignCount(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchTotalNotAssignData = async () => {
    try {
      const response = await axiosInstance({
        method: "get",
        url: "https://sdmipl.com/crm-portal/api/notassign/count",
      });

      setTotalNotAssignCount(response.data);
    } catch (err) {
      console.log(err);
    }
  };
  

  useEffect(()=>{
    fetchTotalNotAssignData();
    fetchTotalAssignData();
  },[])

  
      

  if(!data){
    return <Loading />
  }

  return (
    <>
      <div className=" w-full     ">
       
       <div className="w-full  gap-5    grid md:grid-cols-5 sm:grid-cols-2 grid-cols-1  justify-around ">
        <div 
            className={` col-span-1  h-32 shadow-sm shadow-black flex bg-gradient-to-tl to-blue-500 from-blue-700   border-slate-700 backdrop-blur-sm  justify-center items-center flex-col gap-2 rounded-lg`}
          >
            

            <div className="  col-span-1 flex flex-col justify-center  items-center">
            
              <Person3Rounded />
              <p className="text-center text-white">Total Data</p>
            </div>
            <div className="col-span-1">
            <p className="lg:text-4xl md:text-3xl sm:text-2xl text-2xl text-center  font-sans text-white">
            {assignCount.connectcount + totalNotAssignCount.freshcount}
            </p></div>
</div>

          {
            data && data.totalcount.map((items,index)=>{
              const professionStyle = professionStyles[items.call_status] || professionStyles.Default;
              return(
                <div key={index}
            className={` col-span-1  h-32 shadow-sm shadow-black flex  ${professionStyle.color}   border-slate-700 backdrop-blur-sm  justify-center items-center flex-col gap-2 rounded-lg`}
          >
            

            <div className="  col-span-1 flex flex-col justify-center  items-center">
            
              {professionStyle.icon}
              <p className="text-center text-white">{items.call_status || "Not Called"}</p>
            </div>
            <div className="col-span-1">
            <p className="lg:text-4xl md:text-3xl sm:text-2xl text-2xl text-center  font-sans text-white">
             {items.total}
            </p></div>

          </div>
              );
            })
          }


          {
            data && data.interestStatus.filter(
              (item) =>
                item.interest_status !== null &&
                item.interest_status !== "not know"
            ).map((items,index)=>{
              const professionStyle = professionStyles[items.interest_status] || professionStyles.Default;
              return(
                <div key={index}
            className={` col-span-1  h-32 shadow-sm shadow-black flex  ${professionStyle.color}  border-slate-700 backdrop-blur-sm  justify-center items-center flex-col gap-2 rounded-lg`}
          >
            

            <div className="  col-span-1 flex flex-col justify-center  items-center">
            {professionStyle.icon}
              <p className="text-center text-white">{items.interest_status}</p>
            </div>
            <div className="col-span-1">
            <p className="lg:text-4xl md:text-3xl sm:text-2xl text-2xl text-center  font-sans text-white">
             {items.total}
            </p></div>

          </div>
              );
            })
          }

          

          

          <div
            className={` col-span-1 h-32 shadow-sm shadow-black flex  bg-gradient-to-tl from-yellow-700 to-yellow-500 border-slate-700 backdrop-blur-sm  justify-center items-center flex-col gap-2 rounded-lg`}
          >
            

            <div className="  col-span-1 flex flex-col justify-center  items-center">
              <Repartition className="text-center text-white"/>
              <p className="text-center text-white">Not Assign</p>
            </div>
            <div className="col-span-1">
            <p className="lg:text-4xl md:text-3xl sm:text-2xl text-2xl text-center font-sans text-white">
             {totalNotAssignCount.freshcount}
            </p></div>

          </div>

          <div
            className={` col-span-1 h-32 shadow-sm shadow-black flex   bg-gradient-to-tl from-purple-500 to-violet-700 border-slate-700 backdrop-blur-sm  justify-center items-center flex-col gap-2 rounded-lg`}
          >
            

            <div className="  col-span-1 flex flex-col justify-center  items-center">
              <HowToReg className="text-center text-white"/>
              <p className="text-center text-white">Assigned Data</p>
            </div>
            <div className="col-span-1">
            <p className="lg:text-4xl md:text-3xl sm:text-2xl text-2xl text-center font-sans text-white">
              {assignCount.connectcount}
            </p></div>

          </div>
        </div>


       

        <div className=" w-full my-4 flex flex-col md:flex-row justify-center items-center gap-2 ">
          
          <div className="md:w-6/12 w-full flex justify-center items-center ">
            <PieChartData data={data}/>
          </div>

          <div className="md:w-6/12 w-full my-5 flex justify-center items-center">
      <BarChartData data={data}/>
      </div>

        </div>
      </div>
      
    </>
  );
};
export default Analytics;




const BarChartData = ({ data })=>{

  const statusDetails = {
    connected: { icon: "phone-check", color: "#21BC5A" },
    "not connected": { icon: "phone-off", color: "#F44336" },
    "wrong number": { icon: "phone-alert", color: "#F16C15" },
    default: { icon: "phone-outline", color: "#9E9E9E" },
  };

  const interestDetails = {
    interested: { icon: "check-circle", color: "#07ADCB" },
    "not interested": { icon: "cancel", color: "#D02B75" },
  };

  const barsData = [
    ...data && data.totalcount.filter(
      (item) =>
        item.call_status !== null).map(item => ({
      value: item.total,
      label: item.call_status ,
      frontColor: statusDetails[item.call_status]?.color || "blue",
    })),
    ...data && data.interestStatus.filter(
      (item) =>
        item.interest_status !== null &&
        item.interest_status !== "not know"
    ).map(item => ({
      value: item.total,
      label: item.interest_status || "Unknown",
      frontColor: interestDetails[item.interest_status]?.color || "blue",
    }))
  ];

  return(
    <div className="w-full  p-3">
      <BarChart 
        isAnimated
        padding={'30px'}
        height={300}
        barBorderRadius={4}
        frontColor="lightgray"
        showYAxisIndices
        hideRules
        barWidth={50}
        spacing={5}
        initialSpacing={50}
        data={barsData}
        yAxisThickness={0}
        xAxisThickness={0} 
        xAxisLabelTextStyle={{
          fontSize: 10, 
         
        }}
        
        yAxisLabelContainerStyle={{
         margin:10,
          marginBottom:30
        }}
        xAxisLabelContainerStyle={{
          backgroundColor:"#fff"
        }}
        
      />
              
    </div>
  );
}


const PieChartData = ({data})=>{
  const interestDetails = {
    interested: { icon: "check-circle", color: "#4CAF50" },
    "not interested": { icon: "cancel", color: "#F44336" },
  };


  const interestedCount = data && data.interestStatus.filter(
    (item) => item.interest_status === "interested"
  ).reduce((sum, item) => sum + item.total, 0);

  const notInterestedCount = data && data.interestStatus.filter(
    (item) => item.interest_status === "not interested"
  ).reduce((sum, item) => sum + item.total, 0);

  // Calculate the sum of both
  const totalCount = interestedCount + notInterestedCount;
  return(
    <>
    <div className="flex justify-center items-center ">
    <PieChart
              strokeColor="white"
              strokeWidth={4}
              donut
              
              data={data && data.interestStatus.filter(
                (item) =>
                  item.interest_status !== null &&
                  item.interest_status !== "not know"
              ).map(item => ({
                value: item.total,
                label: item.interest_status,
                color: interestDetails[item.interest_status]?.color || "#9E9E9E",
              }))}
              innerCircleColor="#3ca1ff"
              innerCircleBorderWidth={4}
              innerCircleBorderColor={"white"}
              showValuesAsLabels={true}
              showText
              hideRules
              textSize={15}
              textColor="white"
              centerLabelComponent={() => {
                return (
                  <div className="flex flex-col items-center">
                    <p className="text-lg text-white font-semibold">{totalCount}</p>
                    <p  className="text-md text-white">Total</p>
                  </div>
                );
              }}
            />
    </div>

    </>
  );
}