import { Tab, Tabs } from "@nextui-org/react";
import AddTask from "./AddTask";
import TaskReport from "./TaskReport";

const Task = ()=>{
return(
    <>
    <Tabs aria-label="Options" className="shadow-sm shadow-black rounded-xl">
        <Tab key="Add Task" title="Task Report">
        
              <AddTask />
           
        </Tab>
        <Tab key="Task Report" title="Task Report" >
          
             <TaskReport />
        </Tab>
        
      </Tabs>
    </>
);
}
export default Task;